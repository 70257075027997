
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";

export default defineComponent({
  name: "account-settings",
  data() {
    return {
      user: {}
    };
  },
  mounted() {
    setCurrentPageBreadcrumbs("实名认证", ["账户"]);
    this.user = store.getters.currentUser;
  }
});
